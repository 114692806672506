/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import {
  IconNewLogoV4h,
  IconArrowLeft,
  IconMessage,
} from '~/presentation/base/icons';
import Input from '~/presentation/components/UI/input/index';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import Linkto from '~/presentation/components/UI/link';
import history from '~/main/routes/History';
import {
  Container,
  Header,
  Footer,
  Body,
  Content,
  Title,
  Span,
  Back,
  ContainerButtons,
} from './styles/restorePassStyledpage';
import { schema } from '~/validation/validators/password/RequestPasswordResetValidator';
import { makeRemotePostPasswordRecovery } from '~/main/factories/usecases/password/PostPasswordRecovery';
import { iRestorePass } from './interface';
import { PostPasswordRecovery as PasswordRecovery } from '~/domain/usecases/password/remote';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const RestorePass: React.FC = (): JSX.Element => {
  const [emailResult, setEmailResult] = useState<iRestorePass>({
    email: '',
  });

  const today = new Date();

  const { errors, handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schema),
    defaultValues: {
      ...emailResult,
    },
  });

  const passwordRecovery = () => {
    const dataToSend: PasswordRecovery.Params = {
      email: emailResult.email!,
    };

    try {
      const validate = schema.parse(dataToSend);
      makeRemotePostPasswordRecovery()
        .post({ email: emailResult.email! })
        .catch(() => {
          AlertMessage({
            message: intl.formatMessage({
              id: 'Email inválido! Você já está cadastrado no sistema?',
            }),
            type: 'danger',
          });
        });
    } catch (e) {
      console.log('restore password error: ', e);
    }
  };

  function onChangeEmail(value: string) {
    setEmailResult({ email: value });
    setValue('email', value);
  }

  function goBack() {
    history.back();
  }

  const onSubmit = handleSubmit(data => {
    setEmailResult({ ...emailResult, ...data });
  });

  return (
    <Container onSubmit={onSubmit}>
      <Header>
        <Back onClick={goBack}>
          <IconArrowLeft />
          {translator('Voltar')}
        </Back>
        <div />
      </Header>
      <Body>
        <Content>
          <IconNewLogoV4h />
          <Title>{translator('Recuperar senha')}</Title>
          <Span>
            {translator(
              'Insira seu e-mail no campo abaixo e lhe enviaremos um link para redefinir sua senha.',
            )}
          </Span>
          <ContainerButtons>
            <Input
              name="email"
              label={translator('E-mail')}
              placeholder={translator('Digite o email cadastrado')}
              onChange={e => onChangeEmail(e.target.value)}
              register={() => register('email')}
              error={Boolean(errors.email)}
              message={errors.email?.message}
              required
              value={emailResult.email!}
              autoFocus
              icon={IconMessage}
            />
            <Button
              id="btn_send"
              onClick={passwordRecovery}
              type="submit"
              rounded
              height="48px"
            >
              {translator('Enviar')}
            </Button>
          </ContainerButtons>
        </Content>
      </Body>
      <Footer>
        <div className="links">
          {`© ${today.getFullYear()} Copyright | `}
          <Linkto
            to="https://codata.pb.gov.br/lgpd/politica-de-privacidade-codata.pdf"
            title={translator('Política de privacidade')}
          />
          e
          <Linkto
            to="https://codata.pb.gov.br/lgpd/termo-de-uso_assinado.pdf"
            title={translator('Termos de uso')}
          />
        </div>
      </Footer>
    </Container>
  );
};

export default RestorePass;
