import React from 'react';
import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';

import { useLocation } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { makeReduxPatchAppointmentConfirmation } from '~/main/factories/usecases/appointment/PatchAppointmentConfirmationFactory';
import {
  IconLogoNasNuvens,
  IconLogoRNP,
  IconV4Hatendimento,
} from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import { Icon } from '~/presentation/components/icon';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { Button } from '~/presentation/components/UI';
import Linkto from '~/presentation/components/UI/link';
import { FooterImgs } from '../LoginPage/styles/LoginPageStyled';
import {
  Body,
  Container,
  Content,
  Footer,
  Header,
  Text,
  Title,
} from './styles/AppointmentConfirmationStyled';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const AppointmentConfirmationCreate: React.FC = (): JSX.Element => {
  const query = new URLSearchParams(useLocation().search);
  const tokenResult = query.get('token');

  const today = new Date();

  const footerImages = [
    {
      icon: IconLogoRNP,
      name: 'rnp-icon',
    },
    {
      icon: IconLogoNasNuvens,
      name: 'nasnuvens-icon',
    },
  ];

  const appointmentConfirmation = () => {
    if (tokenResult !== null && tokenResult !== undefined) {
      try {
        // !TODO: Analisar porque o Redux não  está funcionando
        // setTimeout(() => {
        //   history.push('/');
        // }, 2000);
        makeReduxPatchAppointmentConfirmation().confirm({
          token: String(tokenResult),
        });
      } catch (e) {
        console.log('Appointment confirmation error: ', e);
      }
    } else {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Erro ao confirmar agendamento. Você realmente agendou um horário?',
        }),
        type: 'danger',
      });
    }
  };

  const loading = useSelector((store: iStore) => store.appointment.loading);

  return (
    <Container>
      <Header>
        <Icon src={IconV4Hatendimento} width="200px" height="100px" />
      </Header>
      <Body>
        <Content>
          <Title>Confirmar Agendamento</Title>

          <Text>
            <span>Bem vindo ao V4H!</span>
            Confirme seu agendamento para que você possa ser atendido.
          </Text>
          <Button
            onClick={appointmentConfirmation}
            variant="primary"
            rounded
            size="medium"
            height="48px"
          >
            Confirmar
          </Button>
        </Content>
      </Body>
      <Footer>
        <FooterImgs>
          {footerImages.map(image => (
            <image.icon data-testid={image.name} />
          ))}
        </FooterImgs>
        {`Copyright ${today.getFullYear()}`}
        <div className="links">
          <Linkto
            to="https://codata.pb.gov.br/lgpd/termo-de-uso_assinado.pdf"
            title={translator('Termos de uso')}
          />
          e
          <Linkto
            to="https://codata.pb.gov.br/lgpd/politica-de-privacidade-codata.pdf"
            title={translator('Política de privacidade')}
          />
        </div>
      </Footer>
      {/* {loading && <V4hSpin fullScreen />} */}
    </Container>
  );
};

export default AppointmentConfirmationCreate;
